import { useState,useEffect } from 'react';
import ProfileEditFields from '../components/manage-items/EditFields';
import MonthlyCosts from '../components/MonthlyCosts';
import Footer from "../components/Footer";
import LanguageSwitcher from "../components/LanguageSwitcher"
import sprite from '../images/icons.svg';
import { useAuth } from "../hooks/useAuth";
import { userProfileLabels } from '../constants';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Profile = () => {

  const { logout, user } = useAuth();
  const { t } = useTranslation(); // `t` function for translations  
  const [fields, setFields] = useState(user.userInfo);
  const [offsets, setOffsets] = useState([0, 100, 100,]); 

  const { page } = useParams();

  useEffect(() => { 
    setOffsets([
      page === 'edit-profile' || page === 'view-cost' ? 100 : 0,  // Second offset value for `edit-profile`
      page === 'view-cost' ? 'initial' : 'none',    // First offset value for `view-cost`
      page === 'edit-profile' ? 'initial' : 'none'  // Second offset value for `edit-profile`      
    ]);
  }, [page]);
  
  return (
    <>
      <div className='wrapper-desktop center card card-large overflow-hidden' style={{padding: '20px 0'}}>
       
        <div className='profile-wrapper flex' style={{left:`-${offsets[0]}%`}}>

          <div className='width-50'>            
     
            <Link aria-label="Home" to="/profile/view-cost">
              {t('view-costs')}
            </Link>
                <br />
                <br />
            <Link aria-label="Home" to="/profile/edit-profile">
              {t('edit-profile')}
            </Link>
     
          </div>

          <div className='width-50 position-relative'>

            <Link aria-label="Back" to="/profile">
              <svg width="20" height="16" >
                <use href={sprite + "#svg-arrow-back"} />
              </svg> 
            </Link>

            <div className='profile-card' style={{display:`${offsets[1]}`}}>
              <MonthlyCosts 
                  user={user}
              />
            </div>
            
            <div className='profile-card' style={{display:`${offsets[2]}`}}>
              <ProfileEditFields
                fields={fields}
                labels={userProfileLabels}
                setFields={setFields}
              /> 
            </div>     

          </div>
          
        </div>



      {/* { <button className='button' onClick={logout}>Logout</button> } */}

      {/* <LanguageSwitcher /> */}

      </div>    
      
      <Footer user={user} />
    </>
  );
};

export default Profile;
