// item.username används för ti filtrer användara, i ti ader så heiter di type_of_object
const generateItemsEditList = (items,listType,listHeaders,labels,getOptionLabel,filterUserByUsername,openConfirmDialog,editButton,deleteButton) => {  
  return (
  // Fins det bokningar att visa?
  items.length !== 0 ?
    items.map((item, itemIndex) => (
      <li className={`grid items-list_row ${listType}`} key={itemIndex}>
        {listHeaders.map((key, index) => (
          <div className="flex flex-column item-list-all_item" key={index}>
            {itemIndex === 0 && (<strong>{labels[key] ? labels[key][0] : key}</strong>)}
            {/* {key === 'type_of_member' ? formatMemberType(item[key]):item[key]} */}
            {getOptionLabel(key,item[key])}
          </div>
        ))}
        
        {editButton && 
        <>
          <button className="item-list-all_button" onClick={() => filterUserByUsername((item.email?item.email:item.type_of_object),items)}>Edit</button>
          </>
        }
        {deleteButton && 
        <>
          <button className="item-list-all_button" onClick={() => openConfirmDialog(itemIndex,item.id)}>Delete</button>
          </>
        }
      </li>      
    ))
    :
    <li style={{listStyle: 'none', textAlign:'center'}}>Inga bokningar gjorda.</li>
  );
 

  };

export {generateItemsEditList};