const PasswordReset = ({props}) => {

  if (props.resetCode) {
    return (
    <div className='flex flex-align-center-h flex-align-center-v'>            
      <form className='wrapper-desktop card card-large' onSubmit={props.handleSubmitCode}>
        <p>En kod skickades till {props.formData.email}, skriv in koden i fältet nedan.</p>
        <input 
          type="text"
          name="code"
          placeholder='Skriv in koden här'
          value={props.formData.code}
          onChange={props.handleChange}>
        </input>
        {props.errors.code && <p>{props.errors.code}</p>}
        <br></br>
        <p>Ifall du inte fick någon kod dubbelkolla att e-postadressen är korrek och att den inte hamnade i skräpposten.</p>
        <br></br>
        <button className='button' type="submit">Bekräfta</button>
      </form>
      </div>
    );
  }

  if (props.resetPassword) {
    return (
    <div className='flex flex-align-center-h flex-align-center-v'>            
      <form className='wrapper-desktop card card-large' onSubmit={props.handleSubmitPassword}>
        <label>Skriv in dit nya nya lösnord in i fältet nedan.</label>
        <input 
          id="password"
          type="password"
          name="password"
          placeholder='Lösenord'
          value={props.formData.password}
          onChange={props.handleChange}>
        </input>
        <label>Skriv in samma lösenord.</label>
        <input 
          id="confirmPassword"
          type="password"
          name="confirmPassword"
          placeholder='Lösenord'
          value={props.formData.confirmPassword}
          onChange={props.handleChange}>
        </input>
        <br></br>
        <button className='button' type="submit">Bekräfta</button>
      </form>
      </div>
    );
  }

  return (
    <div>
      <h1>Återställ lösenord</h1>  
      <div className="card card-large wrapper-desktop center">
          <form onSubmit={props.handleSubmitEmail}>
            <label htmlFor="email">Skriv in din e-post adress</label>
              <input
                id="email"
                type="email"
                name="email"
                value={props.formData.email}
                onChange={props.handleChange}
              />
            {props.errors.email && <p>{props.errors.email}</p>}
            <button className="button" type="submit">Skicka</button>
          </form>
      </div>
      
    </div>
  );
};

export default PasswordReset;