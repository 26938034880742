import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import  { formatShortDate, getItem, sendEmail }  from '../../utils';
import { availableTimes } from "../../constants";
import sprite from '../../images/icons.svg';

function ConfirmBooking({user, selectedItems, selectedDate,isMobile,availableTables}) {
    //console.log(selectedItems);
       
    let navigate = useNavigate(); 
    const [bookingExists, setbookingExists] = useState(false);
    const [totalPrice, setTotaltPrice] = useState(0);
    const [participantsNumber, setParticipantsNumber] = useState({        
        adult: 0,
        other: 0        
      });    
    const bookingData = { 
        action: 'set-booking',
        tableId: selectedItems['table'],
        date: formatShortDate(selectedDate, 'unformated'),
        timeId: selectedItems['time'],
        email: user.userInfo.email,
        invited_user: '0',
        invited_adult: participantsNumber.adult,
        invited_other: participantsNumber.other,
        isMobile
    }
    const [availablePrices, setAvailablePrices] = useState([]);
    const selectedIdName = availableTables.find(item => item.id === selectedItems['table'][0])

    const [visitorAdultPrice, setvisitorAdultPrice] = useState();
    const [visitorOtherPrice, setVisitorOtherPrice] = useState();

    let timeId = availableTimes.find(item => item.id === selectedItems['time'][0]);
    timeId = timeId['type_of_object'];
    if(selectedItems['time'].length === 2) {
        const tempId = availableTimes.find(item => item.id === selectedItems['time'][1]);
        timeId += ' & ' + tempId['type_of_object'];
    }
            

    useEffect(() => {        
        const fetchIdNames = async (action) => {
            const response = await getItem({
                action,
                condition: bookingData.email,
                table:selectedItems['table'][0]
            });            
            setAvailablePrices(response); 
            setvisitorAdultPrice( response['visitorAdult'] );
            setVisitorOtherPrice( response['visitorOther'] );
            setTotaltPrice( response['user'] );
        };        
        
        fetchIdNames('get-all-prices');        
    }, []); 
    
    

    useEffect(() => {        
    // useEffect utan parameter sko vel boda köras jämför me hitt utan föst vel? vel?!
    // He gär e i alla fall int så availablePrices['visitorAdult'] er undefined tils useEffect som körs "föst" körs ti anna.
    if(availablePrices['visitorAdult']) {                        
        console.log(participantsNumber.adult * availablePrices['visitorAdult']);
            setTotaltPrice( () => {
                return (   
                    parseFloat(participantsNumber.adult * availablePrices['visitorAdult']) +
                    parseFloat(participantsNumber.other * availablePrices['visitorOther']) +
                    parseFloat(availablePrices['user'])
                )
            }
        )
    }
    }, [participantsNumber]);

    const formData = {
        type: 'booking-confirmation'
    }


    const saveItem = async (data) => {
        //console.log(data);
        try {        
            const response = await fetch('https://www.theclub.fi/booking/controler/bookings.php', {
                method: 'POST',
                mode: "cors", // no-cors, *cors, same-origin            
                body: JSON.stringify({
                    ...data
                })
            });
    
            if (!response.ok) {
                throw new Error('Failed to save item');
            } 
            else {
                const jsonData = await response.json();
                if(!jsonData['exists']) {

                    sendEmail({
                        action: 'send-email',
                        user: user.userInfo.firstname,
                        email: user.userInfo.email,
                        time: timeId,
                        date: formatShortDate(selectedDate),
                        table: selectedIdName['type_of_object'],
                        formData 
                      })

                    navigate('/active-bookings');                    
                }
                else {
                    setbookingExists(true);
                    setTimeout(() => {
                        setbookingExists(false);
                    }, 10000);                    
                }
            }
        } catch (error) {
            console.error('Error saving item:', error);
        }
    };

    const handleIncrement = (type) => {
        setParticipantsNumber((prevParticipants) => {
        if (prevParticipants.other + prevParticipants.adult >= 4) return prevParticipants;        
            
            const newAdults = type === 6 ? prevParticipants.adult + 1 : prevParticipants.adult;
            const newOthers = type === 7 ? prevParticipants.other + 1 : prevParticipants.other;
        
            return {
                adult: newAdults,
                other: newOthers
            };
        });
    };
    
    const handleDecrement = (type) => {
        setParticipantsNumber((prevParticipants) => {
            if (prevParticipants.other + prevParticipants.adult <= 0) return prevParticipants;      
       
            const newAdults = type === 6 && prevParticipants.adult > 0 ? prevParticipants.adult - 1 : prevParticipants.adult;
            const newOthers = type === 7 && prevParticipants.other > 0 ? prevParticipants.other - 1 : prevParticipants.other;
        
            return {            
                adult: newAdults,
                other: newOthers
            };
        });
    }
    console.log(visitorAdultPrice);
    return(
    <div id="screen-body-confirm" className="card card-large wrapper-width center flex flex-column width-100 max-width">          
        <strong className='flex-1' style={{marginBottom: "10px"}}>Väl antal deltagare</strong>

        <p className="font-smal color-gray" style={{marginBottom: "10px"}}>Välj antalet deltagare som kommer att spela vid ditt bokade bord - max 5 inklusive dig själv.</p>

        <div className='flex flex-align-center-h'>
            <p className="font-smal color-gray">Besökare vuxen:</p>
        
            <svg onClick={() => handleDecrement(6)} width="31" height="27" >
                <use href={sprite + "#svg-minus"} />
            </svg>
            
            <span style={{width: "20px",textAlign:"center"}}>{participantsNumber.adult}</span>
            
            <svg onClick={() => handleIncrement(6)} width="31" height="27" >
                <use href={sprite + "#svg-plus"} />
            </svg>

        </div>

        <div className='flex flex-align-center-h' style={{marginBottom: "16px"}}>
            <p className="font-smal color-gray">Besökare student/minderårig:</p>
        
            <svg onClick={() => handleDecrement(7)} width="31" height="27" >
                <use href={sprite + "#svg-minus"} />
            </svg>
            
            <span style={{width: "20px",textAlign:"center"}}>{participantsNumber.other}</span>
            
            <svg onClick={() => handleIncrement(7)} width="31" height="27" >
                <use href={sprite + "#svg-plus"} />
            </svg>

        </div>
        
        <span className="gray-line" style={{marginBottom: "12px"}}></span>

        <strong style={{marginBottom: "3px",display: "inline-block"}}>Sammandrag</strong>
        <p className="font-smal color-gray" style={{marginBottom:" 17px"}}>Kontrollera att följande uppgifter är korrekta</p>

        <div className="confirm-input-group">
            <span className='label'>Namn:</span>
            <span className="confirm-input">{user.userInfo.firstname} {user.userInfo.lastname}</span>
        </div>
        <div className="confirm-input-group">
            <span className='label'>Telefon:</span>
            <span className="confirm-input">{user.userInfo.tel}</span>
        </div>
        <div className="confirm-input-group">
            <span className='label'>E-post:</span>
            <span className="confirm-input">{user.userInfo.email}</span>
        </div>
        <div className="confirm-input-group">
            <span className='label'>Dag:</span>
            <span className="confirm-input">{formatShortDate(selectedDate)}</span>
        </div>
        <div className="confirm-input-group">
            <span className='label'>Tidpunkt:</span>
            <span type="text" className="confirm-input" id="booking-time">
                {timeId}
            </span>
        </div>
        <div className="flex confirm-input-group">
            <label htmlFor="table">Bord</label>
            <span className="confirm-input" id="booking-table">{selectedIdName['type_of_object']}</span>
        </div>
        <div className="confirm-input-group">
            <span className='label' style={{marginBottom: '3px', display: "inline-block"}}>Antal deltagare:</span><br />
            <span className="confirm-input" id="booking-participant-number" style={{margin: '0px 0px 3px 0px', display:'block'}}>Besökare vuxen: &nbsp;
                {participantsNumber.adult} x {visitorAdultPrice}€
            </span>
            <span className="confirm-input" id="booking-participant-number"style={{marginLeft: '0'}}>Besökare student/minderårig: &nbsp;
                {participantsNumber.other} x {visitorOtherPrice}€
            </span>
        </div>

        <span className="gray-line"></span>

        <strong style={{marginBottom: "11px", display: "inline-block"}}>TOTALT:&nbsp; 
            { (totalPrice * selectedItems['time'].length).toFixed(2)  }  € 
            ({selectedItems['time'].length} {selectedItems['time'].length <= 1?'timme':'timmar'})
        </strong>

        <p className="font-smal color-gray" style={{marginBottom: "8px"}}>Eventuuella studierabatter räknas vid kassan.<br />Studiekort måste uppvisas</p>

        <span className="gray-line"></span>

        <p className="font-smal flex-1" style={{textAlign: "center"}}>Genom att använda vår tjänst accepterar du våra<br />
            <a className="font-smal" href="/">Vilkor</a>
            &nbsp;och&nbsp;
            <a className="font-smal" href="/">Integritestvilkor</a>
        </p>

        {bookingExists && (<strong style={{marginTop: '30px'}} className='center bold'>En bokning med vald tid eller bord har redan gjorts, navigera tillbaka och välj en ny tid.</strong>)}

        <button className="button" id="button-confirm" onClick={ () => saveItem(bookingData)} style={{marginTop: "19px"}}>
            BEKRÄFTA BOKNINGEN
        </button>
        </div>        
    );
}

export default ConfirmBooking;