import { useState } from 'react';
import { sendEmail } from '../utils';
import { useAuth } from '../hooks/useAuth';
import  { getItem }  from '../utils';



const SubmitForm = ({Form}) => {
  const { login } = useAuth();  
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    tel: '',
    adress: '',
    postnummer: '',
    member_age_group: '1',
    email: '',
    password: '',
    confirmPassword: '',
    code: '',
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [resetCode, setResetCode] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = async (formData, setErrors) => {
    const newErrors = {};

    if (!formData.firstname.trim()) {
      newErrors.firstname = 'Skriv in ditt förnamn';
    }
    if (!formData.lastname.trim()) {
      newErrors.lastname = 'Skriv in ditt efternamn';
    }
    if (!formData.tel.trim()) {
      newErrors.tel = 'Telefonnummer saknas';
    }
    if (!formData.adress.trim()) {
      newErrors.adress = 'Skriv in din adress';
    }

    if (!formData.postnummer.trim()) {
      newErrors.postnummer = 'Skriv in dit postnummer';
    } 
    //else if(!Number.isNaN(formData.postnummer)) {
     //   newErrors.postnummer = 'Postnummer är inte korrekt';  
    //}

    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Epost är inte korrekt';
    }

    var loginInfo = {
      email: formData.email,
      action: 'verify-email',
    } 

    const emailExist = await getItem(loginInfo);
    if(emailExist['exist']) {
      
      newErrors.email = 'E-postadress är redan registrerad';
      console.error("Invalid email");          
    } 

    if (!formData.password) {
      newErrors.password = 'Skriv in ett lösenord';
    } else if (formData.password.length < 6) {
      newErrors.password = 'Lösenord måste vara minst 6 tecken';
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = 'Skriv in lösenordet på nyt';
    } else if (formData.confirmPassword !== formData.password) {
      newErrors.confirmPassword = 'Lösenorden matchar inte';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await validate(formData, setErrors)) {
      // Handle form submission
      console.log('Form submitted', formData);
      sendEmail({
        action: 'send-email',
        //user: user.userInfo.firstname,
        formData
      })
      setSubmitted(true);
    }
  };

  const validateEmail = async (formData, setErrors) => {
    const newErrors = {};    
    var loginInfo = {
      email: formData.email,
      action: 'verify-email',
    }

    try {
      const emailExist = await getItem(loginInfo);
      
      if(!emailExist['exist']) {
        newErrors.email = 'E-postadress hittades inte';
        console.error("Invalid username or password");          
      } 
    } catch (error) {
        console.error("Error during login:", error);
    }

    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const validateCode = async (formData, setErrors) => {
    const newErrors = {};    
    var loginInfo = {
      code: formData.code,
      email: formData.email,      
      action: 'verify-code',
    }

    try {
      const codeExist = await getItem(loginInfo);
      
      if(!codeExist['exist']) {
        newErrors.code = 'Koden hittades inte eller har gått ut';
        console.error("Invalid code");          
      } 
    } catch (error) {
        console.error("Error finding code:", error);
    }

    if (!formData.code) {
      newErrors.code = 'Code is required';
    } else if (formData.code.length !== 5) {
      newErrors.code = 'Code is invalid';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const validatePassword = async (formData, setErrors) => {
    const newErrors = {};  

    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters';
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = 'Please confirm your password';
    } else if (formData.confirmPassword !== formData.password) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmitEmail = async (e) => {
    e.preventDefault();

    try {
      if (await validateEmail(formData, setErrors)) {
        // Handle form submission
        console.log('Form submitted', formData);
        sendEmail({
          action: 'send-email',
          formData
        })
        setResetCode(true);
      }      
    } catch (error) {
      console.error("Error verifying email:", error);
    }



  };

  const handleSubmitCode = async (e) => {
    e.preventDefault();   

    try {      
      if (await validateCode(formData, setErrors)) {
        // Handle form submission
        console.log('Form submitted', formData);
        setResetCode(false);
        setResetPassword(true);
      }      
    } catch (error) {
      console.error("Error verifying code:", error);
    }

  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();

    var loginInfo = {      
      code: formData.code,
      email: formData.email,
      password: formData.password,
      seicrit: process.env.REACT_APP_HEMLI_KODE,
      action: 'reset-password',
    }

    if (await validatePassword(formData, setErrors)) {

      try {
        const resetSuccess = await getItem(loginInfo);
        
        if(resetSuccess['reset']) {
            await login( formData.email );
        } else {
            console.error("Invalid userinput");          
        }
      } catch (error) {
          console.error("Error password reset:", error);
      }

    setResetCode(false);
    setResetPassword(false);

    }



  };

  const handleUserConfirmation = async (e) => {
    e.preventDefault();

    var loginInfo = {      
      code: formData.code,
      email: formData.email,
      seicrit: process.env.REACT_APP_HEMLI_KODE,
      action: 'verify-login',
    }





    try {
      const isLegit = await getItem(loginInfo);

      
      
      if(isLegit['isLogedIn']) {     
        const userInfo = await getItem({
          username: formData.email,
          password: formData.password,
          action: 'login'
        });

        if(userInfo) {  
          await login( {userInfo} );
        }
        
        
      } else {
          console.error("Invalid username or password");          
      }
    } catch (error) {
        console.error("Error during login:", error);
    }
    
  };

  const formParams = {
    submitted,
    formData,
    handleSubmit,
    handleSubmitEmail,
    handleSubmitPassword,
    handleSubmitCode,
    handleChange,
    handleUserConfirmation,
    errors,
    resetCode,
    resetPassword
  }

  return ( 
    <>
      <Form 
        props={formParams} 
        setErrors={setErrors}
      />
    </>    
  );
};

export default SubmitForm;
