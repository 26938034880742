import { Link } from "react-router-dom";
import sprite from '../images/icons.svg';

function Footer({user}) {    
    

    let homeLink = '/booking-form';
    let profileLink = '/booking-form';

    if(user.userInfo.userAccess === '125633534') {
        homeLink = '/manage-bookings';
        profileLink = '/manage-users';
    }

    return(
        <footer id="app-footer" className="flex flex-align-center-v">

            <div className="wrapper-width flex flex-align-center-h flex-align-between">

                <Link aria-label="Home" to={homeLink}>
                <svg width="21" height="22" style={{stroke:'#a5a5a5',strokeWidth:'2px'}}>
                        <use href={sprite + "#svg-home"} />
                    </svg>
                </Link>

                <Link aria-label="Homepage" to="https://www.theclub.fi">
                    <svg width="64" height="45">
                        <use href={sprite + "#svg-logo"} />
                    </svg>                     
                </Link>
                
                <Link aria-label="Profile" to={profileLink}>
                    <svg width="17.58" height="22" style={{stroke:'#a5a5a5',strokeWidth:'2px',strokeLinecap:'round',strokeLinejoin:'round'}}>
                        <use href={sprite + "#svg-profile"} />
                    </svg>
                </Link>

            </div>
        </footer>
    );
}

export default Footer;