import { useState,useEffect,useRef } from "react";
import { getItem,formatMemberType,formatShortDate } from "../utils";
import { userLabels,priceLabels,bookableObjectsLabels,selectedBookingsLabels,availableTimes,memberFeeLabels } from "../constants";
import { generateItemsEditList } from "../components/manage-items/generateItemsEditList";
import AddUser from "../components/manage-items/AddUser";
import ProfileEditFields from "../components/manage-items/EditFields";
import DateSelector from "../components/DateSelector";
import MonthSelector from "../components/MonthSelector"
import Footer from "../components/Footer";
import { useAuth } from "../hooks/useAuth";
import sprite from '../images/icons.svg';

const ListAllUser = () => {
  document.title = 'The Club - Manage items';

  const { user } = useAuth();  

  // Väljer vilka rubriker osm skall visas ifall alla inte ska visas som fins i ex. edit
  const headerUsers = ['username', 'firstname', 'lastname', 'email', 'type_of_member'];
    const headersFees = { 
    member_fee: {
        0: 'Medlemsavgift',
        1: 'number',
    }
} 

  const [fields, setFields] = useState();
  const [items, setItems] = useState({ });
  const [listType, setListType] = useState('get-all-users');
  const [labels, setLabels] = useState(userLabels);
  const [itemList, setItemList] = useState();
  const [showForm, setShowForm] = useState();
  const [isFading, setIsFading] = useState(false);
  const [editList, setEditList] = useState(true);
  const [deleteList, setDeleteList] = useState(true);  
  const [listHeaders, setlistHeaders] = useState(headerUsers);
  const [priceGroupOptions, setPriceGroupOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());    
  const [itemToDelete, setItemToDelete] = useState(null);
  const confirmDialogRef = useRef(null);

  
  useEffect(() => {
      const fetchPriceGroups = async () => {
        const response = await getItem({ action: "get-object-price-names" });
        setPriceGroupOptions(response);
      };
  
      fetchPriceGroups();
    }, []);
  
    const getOptionLabel = (key, option) => {
      switch (key) {
        case 'price_group':
          //const item = priceGroupOptions.find(item => item.id === option);
          //return item ? item.type_of_object : 'Välj';          
          return priceGroupOptions[option] ? priceGroupOptions[option] : 'Välj';          
        case 'type_of_member':      
          return formatMemberType(option);    
        case 'bookable':
          return option === 'Välj'? option:(option === '1'? 'Ja':'Nej');
        case 'timeId':
            let timeId = availableTimes.find(item => item.id === option);
            return timeId['type_of_object'];    
      default:
        return option;
      }
      
    };


useEffect(() => {
  const fetchAndgenerateItemsEditList = async (condition = null) => {
    try {
      const response = await getItem({
        action: listType,
        type: 'list',
        condition: formatShortDate(selectedDate,'unformated'),
        user: user.userInfo.email
      });
      setItems(response);
      setItemList(generateItemsEditList(response,listType,listHeaders,labels,getOptionLabel,filterUserByUsername,openConfirmDialog,editList,deleteList));
    } catch (error) {
      console.error('Error fetching booked items:', error);
    }
  };
  
  fetchAndgenerateItemsEditList();
}, [fields,listType,listHeaders,selectedDate]);


const openConfirmDialog = (index,itemid) => {
  setItemToDelete([index,itemid]); // Set the item index to be deleted
  confirmDialogRef.current.showModal(); // Open the dialog
};

const closeConfirmDialog = () => {
  confirmDialogRef.current.close(); // Close the dialog without deleting
  setItemToDelete(null); // Reset the item to delete
};

const handleDelete = () => {
  setItemList(prevItems => prevItems.filter((_, i) => i !== itemToDelete[0]));
  getItem({
    action: "ta-bort",
    listType,
    conditionValue: itemToDelete[1]
  });
  closeConfirmDialog(); // Close the dialog after deletion
};


  const filterUserByUsername = (filterBy,items) => {
    console.log(filterBy)
    ToggleForm('edit');
    let item = null;
    setTimeout(() => {
      // finsa no na orsak ti vafö e valt såden...
      if(listType === 'get-all-users' || listType === 'get-all-member-fees') {
        item = items.find(item => item.email === filterBy);
      }
      else {
        item = items.find(item => item.type_of_object === filterBy);
      }
      
      if (item) {        
        setFields(item);
      } else {
        console.error('User not found');
      }
    }, 250);
  };  

  const handleAddItem = async () => {
    const actionType = listType.split("-");
    try {
      const response = await getItem({
        action: "add-" + actionType[2],
        items: fields
      });
      if (response.success) {
        setItems([...items, fields]);
        setItemList(generateItemsEditList([...items, fields],listType,listHeaders,labels,getOptionLabel,filterUserByUsername,openConfirmDialog,editList,deleteList));
        setFields({});        
      } else {
        console.error('Error adding item:', response.error);
      }
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const ToggleForm =(show) => {    
    setFields({}); 
    setTimeout(() => {
      setShowForm(show);
    }, 250);
    setIsFading(true)
  }


  const ToggleItemsList = (listType,labels,headers,isEditList,isDeleteList) => {
    setShowForm(false);
    setFields({}); 
    setLabels(labels);
    setListType(listType);    
    setlistHeaders(headers);
    setEditList(isEditList)
    setDeleteList(isDeleteList)
  }

  useEffect(() => {
    if (isFading) {
      const timer = setTimeout(() => {
        setIsFading(false);
      }, 250); // Duration of the fade-out transition

      return () => clearTimeout(timer);
    }
  }, [showForm,isFading]);

  return (
      <>
        <div id="select-manage-button" className="wrapper-width ">
          <button 
            onClick={ ()=>ToggleItemsList('get-all-users',userLabels,headerUsers,true,true) } 
            className={`${listType === 'get-all-users' && 'selected-button'} selectable-button bold`}
          >
          Alla användare
          </button>
          <button 
            onClick={ ()=>ToggleItemsList('get-all-prices',priceLabels,Object.keys(priceLabels),true,true) } 
            className={`${listType === 'get-all-prices' && 'selected-button'} selectable-button bold`}
          >            
          Priser
          </button>
          <button 
            onClick={ ()=>ToggleItemsList('get-all-objects',bookableObjectsLabels,Object.keys(bookableObjectsLabels),true,true) } 
            className={`${listType === 'get-all-objects' && 'selected-button'} selectable-button bold`}
          >
          Bokningsbara objekt
          </button>
          <button 
            onClick={ ()=>ToggleItemsList('get-selected-bookings',selectedBookingsLabels,Object.keys(selectedBookingsLabels),false,false) } 
            className={`${listType === 'get-selected-bookings' && 'selected-button'} selectable-button bold`}
          >
          Lista bokningar
          </button>          
          <button 
            onClick={ ()=>ToggleItemsList('get-all-member-fees',headersFees,Object.keys(memberFeeLabels),true,false) } 
            className={`${listType === 'get-all-member-fees' && 'selected-button'} selectable-button bold`}
          >
          Medlemsavgifter
          </button>         
          
        </div>

        {!editList && !deleteList && 
          <div style={{paddingRight: '577px'}}>
            <DateSelector 
              selectedDate={selectedDate} 
              setSelectedDate={setSelectedDate}
              type={'desktop'}
            />  
          </div>
        }

        {editList && !deleteList && 
          <div style={{paddingRight: '577px'}}>
            <MonthSelector 
              selectedDate={selectedDate} 
              setSelectedDate={setSelectedDate}
              type={'desktop'}
            />
          </div>
        }

        <div className="flex  wrapper-width center">          

          <ul id="items-list" className={`card card-large padding-card width-max-content position-relative ${listType}`} style={{marginRight: '20px'}}>

            { editList && deleteList  && 
              <svg className="position-absolute" onClick={ () => { ToggleForm('add')}} width="31" height="27" style={{top: '30px', right: '31px'}} >
                <use href={sprite + "#svg-plus"} />
              </svg>
            }



            {itemList ? itemList:<div className="spinner"></div>}

            <dialog ref={confirmDialogRef} className="confirm-dialog">
              <h2>Bekräfta redering</h2>
              <p>Är du säker på att du vill rader detta fält?</p>
              <div className="dialog-buttons flex flex-align-center-v">
                <button className="dialog-button" onClick={handleDelete}>Ja</button>
                <button className="dialog-button" onClick={closeConfirmDialog}>Nej</button>
              </div>
            </dialog>

          </ul>
          <div id="items-list-edit" className={`width-max-content ${isFading ? 'fade-out' : 'fade-in'}`} style={{marginLeft: '20px'}}>
            {showForm === 'edit' && fields &&
            <ProfileEditFields
              fields={fields}
              labels={labels}
              setFields={setFields}
              getOptionLabel={getOptionLabel}
              listType={listType}
              selectedDate={selectedDate}
            />            
            }
            {showForm === 'add' && 
              <AddUser 
                labels={labels}
                fields={fields}
                setFields={setFields}
                handleAddItem={handleAddItem}
                getOptionLabel={getOptionLabel}
              />
            }
          </div>
        </div>

        <Footer user={user} />
      </>
    );
  };
  
  export default ListAllUser;