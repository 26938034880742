import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const AdminRoute = ({ children }) => {  

  const { user } = useAuth();

  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }

  if (user && user.userInfo.userAccess !== '125633534' ) {
    // user is not authorised
    return <p>Not authorised</p>;
  }

  return children;
};