import { Link } from "react-router-dom";
//import { SignJWT, jwtVerify }  from 'jose'

import LoginForm from '../components/login/LoginForm';

function LoginPage() {

/*async function test2 (secretCode,jwt) {
    const secret = new TextEncoder().encode(
        secretCode,
      )

      const { payload, protectedHeader } = await jwtVerify(jwt, secret
        // , {
        //     issuer: 'urn:example:issuer',
        //     audience: 'urn:example:audience',
        //   }
      )
      
      //console.log(protectedHeader)
      //console.log(payload)

      

}


async function test() {

    const secretCode = process.env.REACT_APP_HEMLI_KODE;

    const secret = new TextEncoder().encode(
        secretCode,
      )
      const alg = 'HS256'
      
      const jwt = await new SignJWT({ 'test': 'test2' })
        .setProtectedHeader({ alg })
        //.setIssuedAt()
        //.setIssuer('urn:example:issuer')
        //.setAudience('urn:example:audience')
        .setExpirationTime('2h')
        .sign(secret)
      
      console.log(jwt)

      document.cookie = `valid-user=${jwt}; SameSite=None;`;

    //   document.cookie = `
    //   valid-user=${jwt};
    //   max-age=604800;
    //   ${process.env.NODE_ENV !== 'development' ? 'domain=booking.img-media.fi;' : ''}
    //   ${process.env.NODE_ENV !== 'development' ? 'secure;' : ''}
    //   `

      test2(secretCode,jwt);
}
test();

*/
    return (
        <div className="screen flex flex-column flex-align-top flex-align-center-h ">        

            <h1>Boka pool & darts</h1>
        
            <span className="screen-nav flex flex-align-center-h wrapper-width navigate-back" style={{margin: "20px auto 59px"}}>
                {/* <a className="screen-nav-back" href="/" title="Back">
                    <svg width="20" height="16" >
                            <use href={sprite + "#svg-arrow-back"} />
                    </svg>                     
                </a> */}
                <h2>Logga in</h2>
            </span>

            <div id="screen-body-login" className="card card-large flex flex-column flex-align-top flex-align-center-h wrapper-desktop" style={{paddingTop: "38px;padding-bottom:46px"}}>

               <LoginForm />

               <Link to="/password-reset">Glömt dit lösenord?</Link>

                <p style={{margin: "25px auto 26px"}}>Har du inget konto? <Link to="/register">Registrera dig</Link></p>

                <p className="font-smal" style={{textAlign: "center"}}>Genom att använda vår tjänst accepterar du våra<br />
                    <Link className="font-smal" to="/terms">Vilkor</Link>
                    &nbsp;och&nbsp;
                    <Link className="font-smal" to="/gdpr">Integritestvilkor</Link>
                </p>

            </div> 
            
        </div>
    );
  }

export default LoginPage;