import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import  { getItem }  from '../../utils';

const LoginForm = () => {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const handleLogin = async (e) => {
    e.preventDefault();
    // Here you would usually send a request to your backend to authenticate the user
    // For the sake of this example, we're using a mock authentication
    
    var loginInfo = {
      username,
      password,
      seicrit: process.env.REACT_APP_HEMLI_KODE,
      action: 'login',
    }

    try {
      const userInfo = await getItem(loginInfo);
      
      if(userInfo['isLogedIn']) {
          await login({ userInfo });
      } else {
          console.error("Invalid username or password");          
      }
    } catch (error) {
        console.error("Error during login:", error);
    }
  };
  return (
    <div>
      <form onSubmit={handleLogin}>
      
  
          <label htmlFor="username">E-post eller användarnamn:</label>
          <input
            id="e-mail"
            name="e-mail"
            type="text"            
            placeholder="Skriv in din e-post eller användarnamn"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <label htmlFor="password">Lösenord:</label>
          <input
            id="password"
            name="password"
            type="password"
            placeholder="Skriv in ditt lösenord"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
    
        <button className="button" id="button-login" style={{margin: "19px 0 38px"}}>Login</button>
                                
      </form>
    </div>
  );
};

export default LoginForm;