import { userTypes } from "../constants";

const formatDate = (date) => {
    const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString('sv-SE', options);
};

const formatShortDate = (date, type = null) => {

    // Om date skickas som en string och inte en Date
    if (typeof date === 'string') {
        date = new Date(date);
    }

    if(type === 'unformated') {
        const options = { year: 'numeric',  day: 'numeric', month: 'numeric', };
        return date.toLocaleDateString('sv-SE', options);
    }
    else {
        const daysOfWeek = ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'];
        const dayName = daysOfWeek[date.getDay()]; // Get the day of the week in Swedish
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
        const year = date.getFullYear();
    
        return `${dayName} ${day}.${month}.${year}`;
    }


};
const formatMemberType = (id) => {
    return userTypes[id];
};

const getItem = async (data) => {
    try {        
        const response = await fetch('https://www.theclub.fi/booking/controler/bookings.php', {
            method: 'POST',
            mode: "cors", // no-cors, *cors, same-origin            
            body: JSON.stringify({
                ...data
            })
        });

        if (!response.ok) {
            throw new Error('Failed to fetch item');
        } 

        // If response is successful, return the response data
        const jsonData = await response.json();
        return jsonData; // Assuming the response is JSON data

    } catch (error) {
        console.error('Error saving item:', error);
    }
};
 
const sendEmail = async (data) => {
    try {        
        const response = await fetch('https://www.theclub.fi/booking/form-handler/form.php', {
            method: 'POST',
            mode: "cors", // no-cors, *cors, same-origin            
            body: JSON.stringify({
                ...data
            })
        });

        if (!response.ok) {
            throw new Error('Failed to save item');
        } 
        // else {
        //     // If the response is successful, navigate to a different page
        //     navigate('/success'); // Replace '/success' with the path to the success page
        // }
    } catch (error) {
        console.error('Error saving item:', error);
    }
};

const formatTimeName = (timeId) => {    
    const availableTimes = ["07:00–08:00","08:00–09:00","09:00–10:00","10:00–11:00","11:00–12:00","12:00–13:00","13:00–14:00","14:00–15:00","15:00–16:00","16:00–17:00","17:00–18:00","18:00–19:00","19:00–20:00","20:00–21:00","21:00–22:00","22:00–23:00","23:00–00:00","00:00–01:00"]

    return availableTimes[timeId]; 
}

const formatTableName = (tableId) => {    
    const availableTables = ["Pool 1","Pool 2","Pool 3","Pool 4","Pool 5","Pool 6","Pool 7","Pool 8","Pool 9","Pool 10","Pool 11","Snooker"];

    return availableTables[tableId];
}


export {
    getItem,
    sendEmail,
    formatDate,
    formatShortDate,
    formatTimeName,
    formatTableName,
    formatMemberType
}

