// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    fi: {
      translation: {
        "edit-profile": "Muokkaa profiilia",
        "view-costs": "Katso pelimaksut ",
      }
    },
    sv: {
      translation: {
        "edit-profile": "Editera profil",
        "view-costs": "Visa spelavgifter",
      }
    },
  },
  lng: "fi", // default language
  fallbackLng: "fi", // fallback language if the current language is missing a translation
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;
