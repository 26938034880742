// DateSelector.js
import { useState } from 'react';
import  { formatShortDate }  from '../utils';
import sprite from '../images/icons.svg';

const DateSelector = ({ selectedDate, setSelectedDate, type }) => {  


  const [canChangeBack, setCanChangeBack] = useState(false);
  const [canChangeForward, setCanChangeForward] = useState(true);

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to midnight to only compare dates

  // Get the date that is 7 days from today
  const maxFutureDate = new Date(today);
  maxFutureDate.setDate(today.getDate() + 7);

  const handlePreviousDay = () => {
    const previousDay = new Date(selectedDate);
    previousDay.setDate(selectedDate.getDate() - 1);


    setSelectedDate(previousDay);

    if ( today < previousDay.setHours(0, 0, 0, 0) ) {
        setCanChangeBack(true);
    }
    else {
        setCanChangeBack(false);
    }

    if (previousDay < maxFutureDate) {
      setCanChangeForward(true);
    }

};

const handleNextDay = () => {
    const nextDay = new Date(selectedDate);
    nextDay.setDate(selectedDate.getDate() + 1);
    setSelectedDate(nextDay);
    
    
    if(!canChangeBack) {
        setCanChangeBack(true);
    }

    // Disable the forward button if the next day is the 7th day from today or beyond
    if (nextDay >= maxFutureDate) {
      setCanChangeForward(false);
    } else {
      setCanChangeForward(true);
    }
};

  return (
    
    <span className="select-date flex flex-align-center-h wrapper-width center">

      {canChangeBack || type === 'desktop' ? 
        <svg onClick={handlePreviousDay} style={{transform: 'rotate(180deg)'}} width="31" height="27">
          <use href={sprite + "#svg-arrow-right"} />
        </svg>:
        <svg width="31" height="27">
          <use href={sprite + "#svg-arrow-left"} />
        </svg>
      }      
      
      {formatShortDate(selectedDate)}   

  
      {canChangeForward || type === 'desktop' ? (
        <svg onClick={handleNextDay} style={{ cursor: 'pointer' }} width="31" height="27">
          <use href={sprite + "#svg-arrow-right"} />
        </svg>
      ) : (
        <svg width="31" height="27" style={{transform: 'rotate(180deg)'}}>
          <use href={sprite + "#svg-arrow-left"} />
        </svg>
      )}

    </span>
  );
};

export default DateSelector;